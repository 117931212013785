import type {
  CardData,
  ViewportEventData,
  Payload,
  ViewportVisibilityContextProps,
  ViewportVisibilityProviderProps,
} from './viewport-visibility.types';

import {
  createContext,
  useCallback,
  useState,
  useEffect,
  useRef,
  useContext,
} from 'react';

import { useLocation } from 'react-router-dom';

import { restClient } from '../../common/rest-client';
import api from '../../constants/api.json';

const {
  PATH_API: { SIGNALS },
} = api;

const sendEvent = async (event: ViewportEventData) => {
  try {
    await restClient.post(`/${SIGNALS.BASE}/${SIGNALS.VIEWPORT_TRACKING}`, {
      data: event,
    });
  } catch (error: unknown) {
    console.error('Error sending data to event collector: ', error);
  }
};

export const ViewportVisibilityContext =
  createContext<ViewportVisibilityContextProps>({
    addCard: () => {},
    initializePayload: () => {},
    eventData: null,
    setFilterChange: () => {},
    sendEventData: () => {},
  });

// This provider is pending a refactor
export const ViewportVisibilityProvider = ({
  children,
  initialMetadata,
}: ViewportVisibilityProviderProps) => {
  const useCheckLocation = () => {
    try {
      return useLocation();
    } catch {
      return null;
    }
  };
  const [cards, setCards] = useState<Array<CardData>>([]);
  const [mergedEventData, setMergedEventData] =
    useState<ViewportEventData | null>(null);
  const [filterChange, setFilterChange] = useState<string | undefined>('');
  const location = useCheckLocation();

  const { source_view, keyword, content_id, ...restMetadata } = initialMetadata;

  const pendingDataRef = useRef({ mergedEventData });

  useEffect(() => {
    pendingDataRef.current = { mergedEventData };
  }, [cards, mergedEventData]);

  const initializePayload = useCallback(
    (payload: Omit<Payload, 'source_view'>) => {
      setMergedEventData((prev) => {
        const isSameSourceView = prev?.payload.source_view === source_view;
        const createdAt = prev?.created_at || Date.now();
        const createdAtISO = new Date(createdAt).toISOString();
        const filterChanged =
          prev?.payload.filter !== filterChange && filterChange;
        const viewportId =
          filterChanged || !isSameSourceView
            ? payload.viewport_id || ''
            : prev?.payload.viewport_id || '';
        const filter = filterChange || 'no_filter';

        return {
          ...prev,
          payload: {
            ...prev?.payload,
            ...payload,
            viewport_id: viewportId,
            source_view: source_view || '',
            filter,
            ...(content_id ? { content_id } : {}),
            ...(keyword ? { query: keyword } : {}),
          },
          metadata: prev?.metadata || { ...restMetadata },
          created_at: createdAtISO,
        };
      });
    },
    [initialMetadata, content_id, filterChange],
  );

  const addCard = useCallback((card: CardData) => {
    setCards((prevCards) => [...prevCards, card]);
  }, []);

  const sendEventData = useCallback(() => {
    if (mergedEventData && cards.length > 0) {
      const dataToSend = {
        ...mergedEventData,
        payload: {
          ...mergedEventData.payload,
          cards,
        },
      };

      sendEvent(dataToSend);
      setCards([]);
    }
  }, [mergedEventData, cards]);

  // Send event data when cards reach 20
  useEffect(() => {
    if (cards.length >= 20) {
      sendEventData();
    }
  }, [cards, sendEventData]);

  // Send event data every 20 seconds
  useEffect(() => {
    const intervalId = setInterval(() => {
      sendEventData();
    }, 20000);

    return () => clearInterval(intervalId);
  }, [sendEventData]);

  // Retrieve pending data from sessionStorage
  useEffect(() => {
    const pendingData = sessionStorage.getItem('pendingData');

    if (pendingData) {
      const parsedData = JSON.parse(pendingData);

      sendEvent(parsedData);
      sessionStorage.removeItem('pendingData');
    }
  }, []);

  // Send event data when the user leaves the viewport
  useEffect(() => {
    const handleMouseLeave = (event: MouseEvent) => {
      if (event.clientY <= 0) {
        sendEventData();
      }
    };

    document.addEventListener('mouseleave', handleMouseLeave);

    return () => document.removeEventListener('mouseleave', handleMouseLeave);
  }, [sendEventData]);

  // Saves pending data when the user navigates
  useEffect(() => {
    const handleRouteChange = () => {
      const { mergedEventData: pendingEvent } = pendingDataRef.current;

      if (pendingEvent) {
        const dataToPersist = JSON.stringify({
          created_at: pendingEvent?.created_at,
          metadata: pendingEvent?.metadata,
          payload: pendingEvent?.payload,
        });

        sessionStorage.setItem('pendingData', dataToPersist);
      }
    };

    window.addEventListener('popstate', handleRouteChange);
    window.addEventListener('replaceState', handleRouteChange);

    return () => {
      handleRouteChange();
      window.removeEventListener('popstate', handleRouteChange);
    };
  }, [location]);

  // Send event data when visibility changes to hidden
  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.visibilityState === 'hidden') {
        sendEventData();
      }
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);

    return () =>
      document.removeEventListener('visibilitychange', handleVisibilityChange);
  }, [sendEventData]);

  // Send data when filter changes
  useEffect(() => {
    if (mergedEventData && cards.length > 0) {
      sendEventData();
    }
  }, [filterChange]);

  // Send data when the user leaves/enters the view on webview
  useEffect(() => {
    const subscriptionIdOnDisappear =
      window.MobileWebKit?.lifecycle.onViewDisappeared(() => {
        sendEventData();
      });

    const subscriptionIdOnAppear =
      window.MobileWebKit?.lifecycle.onViewAppeared(() => {
        sendEventData();
      });

    return () => {
      window.MobileWebKit?.lifecycle.unsubscribe(subscriptionIdOnDisappear);
      window.MobileWebKit?.lifecycle.unsubscribe(subscriptionIdOnAppear);
    };
  }, []);

  return (
    <ViewportVisibilityContext.Provider
      value={{
        addCard,
        initializePayload,
        eventData: mergedEventData,
        setFilterChange,
        sendEventData,
      }}
    >
      {children}
    </ViewportVisibilityContext.Provider>
  );
};

export const useViewport = () => useContext(ViewportVisibilityContext);
