import type { ContentLockModalProps } from './ContentLockModal.type';
import type {
  EventTrackCTA,
  VcmCtaOptionsListType,
} from '../../../@domain/hooks/useVCMTrack/useVCMTrack.type';

import { Modal as AndesModal } from '@andes/modal';
import { useI18n } from 'nordic/i18n';
import { Typography } from '@andes/typography';
import { Button } from '@andes/button';

import { useRestriction } from '../../../context/restrictions-store/restriction.store';
import { useVcmTrack } from '../../../@domain/hooks/useVCMTrack';
import { useFullVCMNavigation } from '../../../@domain/hooks/useFullVCMNavigation';
import { VCM_CTA_OPTIONS_LIST_TYPED } from '../../../@domain/hooks/useVCMTrack/constants';

export const ContentLockModal = ({ data, currency }: ContentLockModalProps) => {
  const { i18n } = useI18n();
  const { navigateToLogin } = useFullVCMNavigation();
  const { isOpenModalLock, setIsOpenModalLock } = useRestriction();
  const { runEventTrack } = useVcmTrack<EventTrackCTA>(undefined, data);

  const handleLogin = (): void => {
    runEventTrack(
      VCM_CTA_OPTIONS_LIST_TYPED.LOGINMODAL as unknown as VcmCtaOptionsListType,
    );
    navigateToLogin(data?.id || '');
  };

  return (
    <AndesModal
      title={i18n.gettext('¿Cómo accedo a ver el contenido exclusivo?')}
      open={isOpenModalLock}
      onClose={() => setIsOpenModalLock(isOpenModalLock)}
      type="card"
      className="modal-content-lock"
    >
      <Typography className="custom-typo-user-guest">
        {i18n.gettext(
          'Para disfrutar el contenido exclusivo de Universal+ Esencial tienes que tener una o más compras en Mercado Libre por {0} en los últimos 90 días.',
          currency,
        )}
      </Typography>

      <div className="content-button">
        <Button fullWidth size="large" onClick={handleLogin}>
          {i18n.gettext('Iniciar sesión')}
        </Button>
      </div>
    </AndesModal>
  );
};
