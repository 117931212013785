import type { RatingAgeSchema } from './base.schema';
import type { z } from 'zod';
import type { StaticDataProviderProps } from '../context';

export interface Image {
  url: string;
  type?: 'backdrop' | 'cardlogo' | 'poster' | 'postermainslider';
}

export interface ImageEpgItem {
  url: string;
  type?: 'backdrop' | 'cardlogo' | 'poster' | 'postermainslider';
}
export interface Partner {
  name: string;
  status: 'ACTIVE' | 'INACTIVE' | 'UNLINKED';
}

export interface User {
  level: number;
  partners: Array<Partner>;
}

export interface LoyaltyContextType {
  user: User;
  version:
    | 'MELI_MAS_AVAILABLE_SUPPORTED'
    | 'MELI_MAS_AVAILABLE'
    | 'MELI_MAS_NOT_AVAILABLE'
    | 'MELI_MAS_TIER_SUPPORTED';
}

export interface Content {
  id: string;
  images: Array<Image>;
  name: string;
  overview?: string;
  type: ContentType;
  genres: Array<string>;
  duration?: number;
  quantity_seasons?: number;
  rating_age: ratingAgeType;
  is_loyalty: boolean;
  start_time?: number;
  end_time?: number;
  content_provider: string;
  highlight?: string;
  position: number;
  is_exact: boolean;
  publish_year: number;
}

export interface ContentBase {
  id: string;
  images: Array<Image>;
  name: string;
  type: ContentType;
  genres: Array<string>;
  rating_age: ratingAgeType;
  is_loyalty: boolean;
  duration: number;
  quantity_seasons: number;
  content_provider: string;
  highlight: string;
  publish_year: number;
  position: number;
  is_exact: boolean;
  restrictions?: Array<string>;
  is_lock_content: boolean;
  isNbcULocked: boolean;
}
export interface ContentMovie extends ContentBase {
  type: ContentType.MOVIE;
  duration: number;
}

export interface ContentShow extends ContentBase {
  type: ContentType.SHOW;
  quantity_seasons: number;
}

export interface ContentLiveChannel extends ContentBase {
  type: ContentType.LIVECHANNEL;
  overview?: string;
  description?: string;
  epg_items: Array<EpgItem>;
}

export interface ContentLiveCard extends ContentBase {
  type: ContentType.LIVECHANNEL;
  overview?: string;
  description?: string;
  epg_item: EpgItem;
}

export interface EpgItem {
  id: string;
  title: string;
  description: string;
  images: Array<Image>;
  type: ContentTypeEpgItem;
  genres?: Array<string>;
  duration?: number;
  directors?: Array<string>;
  producers?: Array<string>;
  writers?: Array<string>;
  cast?: Array<Person>;
  current: boolean;
  film_year: string;
  start_time: number;
  end_time: number;
  episode_number: number;
  season_number: number;
}

interface Person {
  person_name: string;
}

export interface ContentGroup {
  type: ContentType.CONTENT_GROUP;
  id: string;
  contents: Array<CardSearch>;
}

export enum ContentType {
  CONTENT_GROUP = 'content-group',
  LIVECHANNEL = 'LIVECHANNEL',
  MOVIE = 'MOVIE',
  SHOW = 'SHOW',
}

export enum ContentTypeEpgItem {
  EPISODE = 'EPISODE',
  MOVIE = 'MOVIE',
  SHOW = 'SHOW',
}

export interface ResultCards {
  exact: Array<Content>;
  similar: Array<Content>;
  groups: Array<Content>;
}

export interface ContentSearchType {
  content_results: ResultCards;
  loyalty_context: LoyaltyContextType;
  experiment?: StaticDataProviderProps['experiment'];
  min_version_to_vcm_full?: string;
  users_id_allow_list_vcm?: Array<number>;
  live_event_ids_allow_vcm?: Array<string>;
}

export type CardSearch = ContentLiveChannel | ContentMovie | ContentShow;

export type LoyaltyContext = LoyaltyContextType;

export type ratingAgeType = z.infer<typeof RatingAgeSchema>;
