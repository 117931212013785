import type { FeedContainerHookProps } from '../../../components/Feed';
import type { ContextValue } from '../../../pages/hub/context';

import { useCallback, useContext, useEffect, useState } from 'react';

import axios from 'nordic-axios';
import debounce from 'lodash/debounce';
import trimEnd from 'lodash/trimEnd';

import { HubContext } from '../../../pages/hub/context';
import pages from '../../../constants/pages.json';
import { getCarousels, getFeed } from '../repositories';
import {
  getRealFilterTrack,
  ProviderTrack,
  ProviderTrackEventName,
  sendPlatformTracks,
} from '../../../utils/track';
import {
  ApplicationError,
  CustomError,
} from '../../../../services/utils/custom-error';
import { Utils } from '../../../utils';
import { SharedContext } from '../../../context/shared-context/shared.context';
import { useMainTracksStore } from '../../../context/tracks-webview/tracks-webview';
import commons from '../../../constants/commons.json';
import { useSubscribeToBackEvent } from '../../../@domain/hooks/useSubscribeToBackEvent';
import { useStaticData } from '../../../context';

const { HUB_PAGE_FILTER_LANG } = pages;
const { ALLOWED_CAROUSELS } = commons;

export const useFeedContainer = ({
  feed,
  nextPage,
  isWebview,
}: FeedContainerHookProps) => {
  const {
    filter,
    previousFilter,
    data = feed,
    updateFeed,
    updateFilter,
    setInitialData,
    getSnackbarById,
    nextPage: nextPageContext,
    updateCarouselsComponents,
  } = useContext<ContextValue>(HubContext);
  const context = useContext(SharedContext);
  const { mainTracks } = useMainTracksStore();
  const { errorAction } = useStaticData();
  const [isLoading, setIsLoading] = useState(false);

  const updateComponentsCarousels = useCallback(async () => {
    const response = await getCarousels(ALLOWED_CAROUSELS, context);

    updateCarouselsComponents({ data: response.props.components });
  }, [context, updateCarouselsComponents]);

  useSubscribeToBackEvent(updateComponentsCarousels);

  const fetchFeed = useCallback(async () => {
    try {
      setIsLoading(true);
      getSnackbarById('no-internet-connection-error-snackbar')?.hideSnackbar();
      getSnackbarById('no-load-content-error-snackbar')?.hideSnackbar();

      const hubWithFilter =
        Utils.getArrayValuesFromObject(HUB_PAGE_FILTER_LANG);
      const hubWithFilterByRegex = trimEnd(
        hubWithFilter.join('|'),
        '|',
      ).replace(/\//g, '');
      // eslint-disable-next-line security/detect-non-literal-regexp
      const pathFilterRegex = new RegExp(
        `^(${hubWithFilterByRegex})?\\/?`,
        'i',
      );
      const newFilter = filter?.replace(pathFilterRegex, '');

      const feed = await getFeed({
        filter: newFilter,
        config: {
          singletonId: 'hub-feed',
        },
        ...nextPageContext,
        context,
        isWebview,
      });

      const dataTracks = {
        path:
          feed.tracks?.[ProviderTrackEventName.DISPLAY]?.[
            ProviderTrack.MELIDATA
          ]?.path || '',
        typeEvent:
          feed.tracks?.[ProviderTrackEventName.DISPLAY]?.[
            ProviderTrack.MELIDATA
          ]?.type || 'view',
        eventData: {
          filter: getRealFilterTrack(filter),
          ...feed.tracks?.[ProviderTrackEventName.DISPLAY]?.[
            ProviderTrack.MELIDATA
          ]?.event_data,
        },
        tracks: {
          ...mainTracks?.[ProviderTrackEventName.DISPLAY]?.[
            ProviderTrack.MELIDATA
          ]?.event_data,
          ...feed.tracks,
        },
        trackEventName: ProviderTrackEventName.DISPLAY,
        experiments:
          feed.tracks?.[ProviderTrackEventName.DISPLAY]?.[
            ProviderTrack.MELIDATA
          ]?.experiments,
      };

      updateFeed({ data: feed.components, nextPage: feed.nextPage });
      sendPlatformTracks(dataTracks);
      setIsLoading(false);
    } catch (error: unknown) {
      if (axios.isCancel(error)) {
        return;
      }

      let snackbarId = 'no-load-content-error-snackbar';

      if (error instanceof CustomError) {
        if (error.name === ApplicationError.INTERNET_ERROR) {
          snackbarId = 'no-internet-connection-error-snackbar';
        }
      }

      getSnackbarById(snackbarId)?.showSnackbar({
        onActionClick: fetchFeed,
      });

      setIsLoading(false);
    }
  }, [filter, nextPageContext]);

  useEffect(() => {
    if (feed) {
      setInitialData({ data: feed, nextPage });
    }
  }, [feed]);

  useEffect(() => {
    if (previousFilter !== filter) {
      fetchFeed();
    }
  }, [previousFilter, filter]);

  useEffect(() => {
    if (errorAction && data.length > 0) {
      getSnackbarById(errorAction)?.showSnackbar();
    }
  }, [errorAction, data]);

  return {
    feed: data,
    filter,
    onNextPage: debounce(fetchFeed, 50),
    onUpdateFilter: updateFilter,
    isLoading,
    previousFilter,
    hasMore: nextPageContext,
    updateComponentsCarousels,
    getSnackbarById,
  };
};
