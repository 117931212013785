import type { MobileWebkitType } from './mobile-webkit.type';

class Webkit {
  async push({
    url,
    args,
    onSuccess,
    onError,
  }: {
    url: string;
    args?: unknown;
    onSuccess?: () => unknown;
    onError?: () => unknown;
  }) {
    const options = args ? { ...args } : undefined;

    return window?.MobileWebKit?.navigation
      .push(url, options)
      .then(() => onSuccess?.())
      .catch((error) => {
        this.isNewRelicLoaded('Push', error);

        if (onError) {
          onError();
        }
      });
  }

  async pop() {
    return window?.MobileWebKit?.navigation.pop().catch((error) => {
      this.isNewRelicLoaded('Pop HandleBack', error);
    });
  }

  async popToHome() {
    return window?.MobileWebKit?.navigation.popToHome().catch((error) => {
      this.isNewRelicLoaded('Pop to Home HandleBack', error);
    });
  }

  async reload() {
    return window?.MobileWebKit?.navigation.reload().catch((error) => {
      this.isNewRelicLoaded('Reload VCM', error);
    });
  }

  async closeScreens(name = '') {
    return window?.MobileWebKit?.navigation
      .closeScreenGroup()
      .catch((error) => {
        this.isNewRelicLoaded(`Close Screens ${name}`, error);
      });
  }

  async setGroupStore(store: any) {
    return window?.MobileWebKit?.navigation
      .setGroupStore(store)
      .catch((error) => {
        this.isNewRelicLoaded('Set Group Store', error);
      });
  }

  async getGroupStore(store: any) {
    return window?.MobileWebKit?.navigation
      .getGroupStore()
      .then(store)
      .catch((error) => {
        this.isNewRelicLoaded('Get Group Store', error);
      });
  }

  async executeNative({ event, args }: MobileWebkitType) {
    return window?.MobileWebKit?.executeNative({
      method: event,
      args,
    }).catch((error) => {
      this.isNewRelicLoaded(event, error);
    });
  }

  subscribeToBackAction(callback: any) {
    return window.MobileWebKit?.navigation.subscribeToBackAction(callback);
  }

  async unsubscribeToBackAction(callback: any) {
    return window?.MobileWebKit?.navigation.unsubscribeToBackAction(callback);
  }

  async navigateInsideApp(url: string) {
    return window.MobileWebKit?.navigation
      .navigateInside({
        url,
      })
      .catch((error) => {
        this.isNewRelicLoaded('navigateInsideApp', error);
      });
  }

  async shareContent(title: string, data: string) {
    return window.MobileWebKit?.component.actions
      .share(title, data)
      .catch((error) => {
        this.isNewRelicLoaded('shareContent', error);
      });
  }

  getPlatform(): '' | 'android' | 'ios' | 'undefined' {
    return window?.MobileWebKit?.platformInfo?.platform || '';
  }

  async canOpenDeeplink(deeplink: string) {
    return window.MobileWebKit?.navigation
      .canOpenDeeplink(deeplink, {
        only_current_app: true,
      })
      .catch((error) => {
        this.isNewRelicLoaded('canOpenDeeplink', error);
      });
  }

  openLinkInAppBrowser(url: string) {
    window.MobileWebKit?.navigation.openLinkInAppBrowser(url, {
      show_title: true,
    });
  }

  openLinkOutsideApp(url: string) {
    window.MobileWebKit?.navigation.openLinkOutsideApp(url).catch((error) => {
      this.isNewRelicLoaded('openLinkOutsideApp', error);
    });
  }

  private isNewRelicLoaded(event: string, error: any) {
    const status =
      window?.newrelic && typeof window?.newrelic?.noticeError === 'function';

    if (status) {
      const name = new Error(`${event} error`);

      window.newrelic.noticeError(name, {
        error,
      });
    }

    return status;
  }
}

export const MobileWebKit = new Webkit();
