import type { MessageElementProps } from './MessageElement.types';

import { Message as MessageAndes } from '@andes/message';
import classnames from 'classnames';

import { Button } from '../Button';

const namespace = 'andes-custom-message';

export const MessageElement = ({
  color = 'accent',
  labeljsx,
  hierarchy = 'loud',
  rightItem,
  onClick,
}: MessageElementProps) => (
  <MessageAndes
    className={classnames(`${namespace}`, {
      [`${namespace}--no-icon`]: color === 'magenta',
      [`${namespace}__color--magenta`]: color === 'magenta',
    })}
    hierarchy={hierarchy}
    color={color === 'magenta' ? 'accent' : color}
  >
    {labeljsx}
    {rightItem?.props.label && (
      <Button
        className="nbcu-desktop__login-button"
        {...rightItem.props}
        onClick={onClick}
      />
    )}
  </MessageAndes>
);
