import type { ReactNode } from 'react';
import type { UserDevice } from 'frontend-device_detection';
import type { LoyaltyContextType } from '../../@schema/search.schema';

export interface Style {
  background_color: string;
  text_color: string;
  label: string;
}

export interface BusinessRule {
  highlight: string;
  status: 'active' | 'inactive';
  ranking: number;
  style: Style;
}

export type HighlightRules = Record<string, Array<BusinessRule>>;

export type SiteId =
  | 'ALL'
  | 'CBT'
  | 'MBO'
  | 'MCO'
  | 'MCR'
  | 'MCU'
  | 'MEC'
  | 'MGT'
  | 'MHN'
  | 'MLA'
  | 'MLB'
  | 'MLC'
  | 'MLM'
  | 'MLU'
  | 'MLV'
  | 'MNI'
  | 'MPA'
  | 'MPE'
  | 'MPT'
  | 'MPY'
  | 'MRD'
  | 'MSV';

export interface TagColors {
  backgroundColor: string;
  color: string;
}

export type ImagenPartnerType = 'DISNEYPLUS' | 'MAX' | 'STARPLUS';

export enum ImagenPartnerTypeEnum {
  DISNEYPLUS = 'DISNEYPLUS',
  STARPLUS = 'STARPLUS',
  MAX = 'MAX',
}

export enum imagenPartnerType {
  DISNEYPLUS = 'DISNEYPLUS',
  STARPLUS = 'STARPLUS',
  MAX = 'MAX',
}

export interface LoyaltyRules {
  tagText: string;
  tagColors: TagColors;
  icon?: string;
  imagenPartner: Record<ImagenPartnerType, string>;
  isMeliPlus?: boolean;
  footerText: string;
}

export interface Rule<T> {
  conditions: Record<string, any>;
  result: T;
}

export interface LoyaltyRulesConfig {
  default: {
    tagTextRules: Array<Rule<string>>;
    tagColorsRules: Array<Rule<TagColors>>;
    footerTextRules: Array<Rule<string>>;
  };
  siteSpecific: {
    [key in SiteId]?: {
      tagTextRules: Array<Rule<string>>;
      tagColorsRules: Array<Rule<TagColors>>;
      footerTextRules: Array<Rule<string>>;
    };
  };
}

export interface useHighlightRulesTypes {
  label: string;
  color: string;
  textColor: string;
}

export interface WordingBySite extends Partial<Record<string, string>> {
  default: string;
}

export interface RatingAgeRule {
  ratingAge: string;
  wordingBySite: WordingBySite;
}

export type RatingAgeRules = Array<RatingAgeRule>;

export interface GeneralContextType {
  loyaltyContext?: LoyaltyContextType;
  highlightRulesConfig?: HighlightRules;
  loyaltyRulesConfig: LoyaltyRulesConfig;
  rating_ageRulesConfig?: RatingAgeRules;
  device?: UserDevice;
  siteId?: SiteId;
}

export interface RulesContext {
  loyaltyContext?: LoyaltyContextType;
  highlightRulesConfig?: HighlightRules;
  loyaltyRulesConfig: LoyaltyRulesConfig;
  rating_ageRulesConfig?: RatingAgeRules;
}

export interface GeneralContextProviderProps {
  value: GeneralContextType;
  children: ReactNode;
}
