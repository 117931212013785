import type { FC } from 'react';
import type { MediaCardVerticalProps } from '../MediaCardVertical.types';

import Card from '@andes/card';
import classname from 'classnames';

import { Image } from '../../Image/mobile';
import { Link } from '../../Link/Link';
import { PillItemCards } from '../../MediaCard/components/PillItemCards';
import { useCardTracking } from '../../../context/viewport-visibility/useCardTracking/useCardTracking';

const namespace = 'mediacard-vertical';

export const MediaCardVertical: FC<MediaCardVerticalProps> = ({
  size,
  header,
  linkTo,
  carouselType = 'vertical',
  action,
}) => {
  const { background, topLeftItem } = header.default;
  const { contentId, origin } = action || {};

  const viewportVisibilityData = {
    contentId: contentId || '',
    cardPosition: origin?.originPosition || '',
    carouselName: origin?.originNameCarousel || '',
    carouselPosition: origin?.originPositionCarousel || '',
    contentIdPivot: origin?.contentIdPivot || '',
  };

  const { inViewRef } = useCardTracking(viewportVisibilityData);

  if (!background) {
    return null;
  }

  return (
    <Card
      className={classname(namespace, `size--${size}`, `type--${carouselType}`)}
      shadow="elevated"
      ref={inViewRef}
    >
      <Link to={linkTo} className="poster-media-card__link">
        {topLeftItem && (
          <PillItemCards itemProps={topLeftItem?.props} size="small" />
        )}

        <Image
          carousel
          lazy="on"
          {...background.props}
          className="poster-media-card__image"
        />
      </Link>
    </Card>
  );
};
