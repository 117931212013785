import type {
  OptionsListType,
  TrackViewTabName,
  VcmCtaOptionsListType,
} from './useVCMTrack.type';
import type { EpisodesListType } from '../../../context/vcmStore/vcmStore';
import type { Content } from '../../../@schema';

import { useCallback, useMemo } from 'react';

import { sendTrack } from '../../../utils/track';
import { QueryParams } from '../../../utils/query-params';
import { useStaticData } from '../../../context/static-data-context';
import { Utils } from '../../../utils';
import tracksPaths from '../../../constants/paths-tracks.json';
import { useVCMStore } from '../../../context/vcmStore/vcmStore';
import { useRestriction } from '../../../context/restrictions-store/restriction.store';
import COMMONS from '../../../constants/commons.json';
import { useUserContext } from '../../context/user-context';
import { VCM_CTA_OPTIONS_LIST_TYPED } from './constants';
import { ContentType } from '../../../@schema/search.schema';

const { VCM } = tracksPaths;

type DynamicType<T = unknown> = Record<
  string,
  Record<string, Array<string> | number | string> | T | [OptionsListType]
>;

export const useVcmTrack = <T extends DynamicType>(
  currentTab?: TrackViewTabName,
  data?: Content['content'],
) => {
  const { restrictions, permission } = useRestriction();
  const { url, guest_id, experiment, isKeepWatching, siteId, canShareContent } =
    useStaticData();

  const { nbcuUserGuest, nbcuUserLoggedIn } = permission;

  const { tabSelected, episodesList, setEpisodesList } = useVCMStore();
  const { loyalty, sampling } = useUserContext();

  const isLiveChannel = data?.type === ContentType.LIVECHANNEL;

  const isSampling =
    sampling.isReadyMeliPlus ||
    sampling.isVinculatedToMeliPlus ||
    sampling.isUpgradeToMeliPlus;
  const isLoyalty =
    loyalty.isReadyMeliPlus ||
    loyalty.isVinculatedToMeliPlus ||
    loyalty.isUpgradeToMeliPlus;

  const sourcePayload = useCallback(
    (isTrack = true) => {
      const makeSource = QueryParams.filterParamsWithCustomPrefix(url!);

      let makeSourceSnakeCase = Utils.transformCamelCaseToSnakeCase(
        makeSource as Record<string, string>,
      );

      if (isTrack && makeSourceSnakeCase.origin) {
        makeSourceSnakeCase = {
          ...makeSourceSnakeCase,
          type: makeSourceSnakeCase.origin,
        };

        delete makeSourceSnakeCase.origin;
      }

      return makeSourceSnakeCase;
    },
    [url],
  );

  function makeUpgradeLoyaltyLabel(): string {
    if (siteId === 'MLA' || siteId === 'MCO') {
      const level = loyalty?.isUpgradeToMeliPlus?.level;

      if (level && level < 6) {
        return VCM_CTA_OPTIONS_LIST_TYPED.LOYALTY_TRACK.SUSCRIPTION;
      }

      return VCM_CTA_OPTIONS_LIST_TYPED.LOYALTY_TRACK.ACTIVATION;
    }

    if (loyalty.isUpgradeToMeliPlus?.product_code === 'meli_essential') {
      return VCM_CTA_OPTIONS_LIST_TYPED.LOYALTY_TRACK.UPGRADE;
    }

    return VCM_CTA_OPTIONS_LIST_TYPED.LOYALTY_TRACK.SUSCRIPTION;
  }

  function createOptionsList(): Array<string> {
    const opt: Array<string> = [];

    if (canShareContent) {
      opt.push(VCM_CTA_OPTIONS_LIST_TYPED.SHARED_CONTENT);
    }

    if (isLiveChannel) {
      opt.push(VCM_CTA_OPTIONS_LIST_TYPED.LIVE_TRACK.WATCH);
    }

    if (isSampling) {
      if (sampling.isReadyMeliPlus) {
        opt.push(VCM_CTA_OPTIONS_LIST_TYPED.SAMPLING_TRACK.WATCH);
      }

      if (sampling.isVinculatedToMeliPlus) {
        opt.push(VCM_CTA_OPTIONS_LIST_TYPED.SAMPLING_TRACK.LINKING);
      }

      if (sampling.isUpgradeToMeliPlus) {
        opt.push(VCM_CTA_OPTIONS_LIST_TYPED.SAMPLING_TRACK.SUSCRIPTION);
      }
    }

    if (isLoyalty) {
      if (loyalty.isReadyMeliPlus) {
        opt.push(VCM_CTA_OPTIONS_LIST_TYPED.LOYALTY_TRACK.WATCH);
      }

      if (loyalty.isVinculatedToMeliPlus) {
        opt.push(VCM_CTA_OPTIONS_LIST_TYPED.LOYALTY_TRACK.LINKING);
      }

      if (loyalty.isUpgradeToMeliPlus) {
        const label = makeUpgradeLoyaltyLabel();

        opt.push(label);
      }
    }

    if (nbcuUserGuest) {
      opt.push(
        VCM_CTA_OPTIONS_LIST_TYPED.LOGIN,
        VCM_CTA_OPTIONS_LIST_TYPED.KNOWMORE,
        VCM_CTA_OPTIONS_LIST_TYPED.LOGINMODAL,
      );
    }

    if (nbcuUserLoggedIn) {
      opt.push(VCM_CTA_OPTIONS_LIST_TYPED.KNOWMORE);
    }

    if (!isLoyalty && !nbcuUserGuest && !nbcuUserLoggedIn) {
      const defaultOption = isKeepWatching
        ? VCM_CTA_OPTIONS_LIST_TYPED.CONTINUOUS_WATCH
        : VCM_CTA_OPTIONS_LIST_TYPED.WATCH_FREE;

      opt.push(defaultOption);
    }

    return opt;
  }

  const getDataSeason = () => {
    const seasonData = {
      seansonNumber: '',
      seasonId: '',
    };

    if (data?.type === 'SHOW') {
      seasonData.seansonNumber = data.playing_season.number.toString();
      seasonData.seasonId = data.playing_season.id;
    }

    if (data?.type === 'EPISODE') {
      seasonData.seansonNumber = data.season_id;
      seasonData.seasonId = data.season_number.toString();
    }

    return seasonData;
  };

  const obj = useMemo(
    () => ({
      content_id: data?.id,
      content_type: data?.type?.toLowerCase(),
      genres: data?.genres || [],
      pg_rating: data?.parental_control || 'NA',
      source: Utils.transformKeys(sourcePayload(), 'origin_'),
      vertical: COMMONS.VERTICAL_TRACK_VALUE,
      page: 1,
      guest_user_id: guest_id,
      experiment: experiment || undefined,
      options_list: createOptionsList(),
      contents_list: episodesList || [],
      content_tags: [],
      runtime:
        data?.type === 'SHOW'
          ? data?.playing_episode.duration
          : data?.duration || 0,
      restrictions_list: restrictions || [],
      season_id: data?.type === 'MOVIE' ? 'NA' : getDataSeason().seasonId,
      season_number:
        data?.type === 'MOVIE' ? 'NA' : getDataSeason().seansonNumber,
    }),
    [restrictions, url, data, episodesList],
  );

  const runViewTrack = useCallback(
    (data: T) => {
      setEpisodesList(data.contents_list as Array<EpisodesListType>);
      sendTrack({
        path: VCM.VIEW_PAGE,
        typeEvent: 'view',
        eventData: { selected_tab: currentTab || '', ...obj, ...data },
      });
    },
    [restrictions, obj, episodesList],
  );

  const runEventTrack = useCallback(
    (selected_option: VcmCtaOptionsListType) => {
      const { experiment, ...restObjOfTracks } = obj;

      sendTrack({
        path: VCM.SELECT,
        typeEvent: 'event',
        eventData: {
          ...restObjOfTracks,
          restrictions_list: restrictions || [],
          selected_tab: tabSelected,
          selected_option,
        },
      });
    },
    [tabSelected, restrictions, obj, episodesList],
  );

  return { runViewTrack, runEventTrack, sourcePayload } as const;
};
