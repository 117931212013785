import type { TracksPlatform } from '../track/track';
import type { Content, ResultCards, Image } from '../../@schema/search.schema';
import type { Rule } from '../../context/rules-context/rule-context.type';

import { useI18n } from 'nordic/i18n';

import { imagenPartnerType } from '../../context/rules-context/rule-context.type';
import paths from '../../constants/paths-tracks.json';
import { isCurrentLive, isEnded, nowTime } from '../utils';

/**
 * Generates a deep link to access specific content within the application.
 *
 * @param {string} id - The unique identifier of the content.
 * @param {string} [origin='mplay_search'] - The origin of the link.
 * @param {string} [layout='default'] - The layout of the target page.
 * @returns {string} The generated deep link.
 */
export const generateDeepLink = (
  id: string,
  origin = 'mplay_search',
  layout = 'default',
): string =>
  `meli://mplay/vcp?url=/frontend/content/${id}/vcp&origin=${origin}&layout=${layout}`;

/**
 * List of valid content providers.
 */
const VALID_PROVIDERS: Array<imagenPartnerType> = [
  imagenPartnerType.DISNEYPLUS,
  imagenPartnerType.STARPLUS,
  imagenPartnerType.MAX,
  // Add news providers here
];

/**
 * Checks if the given provider is a valid content provider.
 *
 * @param {string} provider - The name of the provider to check.
 * @returns {provider is ImagenPartnerType} - Returns `true` if the provider is valid, otherwise `false`.
 *
 * @example
 * ```typescript
 * if (validContentProvider('DISNEYPLUS')) {
 *   // provider is of type ImagenPartnerType.DISNEYPLUS
 * }
 * ```
 */
export const validContentProvider = (
  provider: string,
): provider is imagenPartnerType =>
  VALID_PROVIDERS.includes(provider as imagenPartnerType);

/**
 * Get URL from the provider.
 *
 * @param {string} provider - The name of content provider.
 * @param {Record<ImagenPartnerType, string>} imagenPartner - Mapping Suppliers to Image URLs.
 * @returns {string} The URL of the corresponding image or a default image.
 */
export const getImageSrc = (
  provider: string,
  imagenPartner: Record<imagenPartnerType, string>,
): string => {
  if (validContentProvider(provider)) {
    return imagenPartner[provider];
  }

  return '';
};

/**
 * Formats a given duration in seconds to a human-readable string in hours and minutes.
 *
 * @param {number} seconds - The duration in seconds.
 * @returns {string} The duration formatted in the format "xh ym".
 *
 * @example
 * ```typescript
 * formatDuration(7200); // "2h 0m"
 * formatDuration(3661); // "1h 1m"
 * ```
 */
export const formatDuration = (seconds: number): string => {
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);

  return `${hours}h ${minutes}m`;
};

/**
 * Creates a tracking object to log a search view on the platform.
 *
 * @param {string} query - The search criteria used.
 * @param {ContentItem[]} contents - Array of objects required for tracking.
 *
 * @example
 * ```typescript
 * const eventTrack = trackSearchView('avatar', contents);
 * // eventTrack will be:
 * // {
 * //   path: '/mercadoplay/search/feed',
 * //   typeEvent: 'view',
 * //   eventData: {
 * //     query: 'avatar',
 * //     vertical: 'mplay',
 * //   },
 * // }
 * ```
 */
const { SEARCH } = paths;

type ContentItem = {
  content_type: string;
  content_id: string;
  content_tags: Array<string>;
  type: string;
};

export const trackSearchViewScrollInfinite = (
  query: string,
  contents: Array<ContentItem>,
  page: number,
) => {
  const eventTrack: TracksPlatform = {
    path: SEARCH.feedPath,
    typeEvent: 'view',
    eventData: {
      query,
      vertical: SEARCH.vertical,
      contents_list: contents,
      page,
    },
  };

  return eventTrack;
};

/**
 * Converts a content object (`Content`) into a content item object (`ContentItem`).
 *
 * @param content - The content object to convert.
 * @returns A `ContentItem` object representing the formatted content.
 */
export const contentToContentItem = (content: Content): ContentItem => ({
  content_type: content.type.toLowerCase(),
  content_id: content.id,
  content_tags: content.genres || [],
  type: 'card',
});

/**
 * Extracts and converts content items from the result cards.
 *
 * @param resultCards - An object containing two result lists: `exact` and `similar`.
 * @returns An array of content items (`ContentItem`) that includes both exact and similar items.
 */
export const getContentItemsFromResultCards = (
  resultCards: ResultCards,
): Array<ContentItem> => {
  const exactItems = resultCards.exact.map(contentToContentItem);
  const similarItems = resultCards.similar.map(contentToContentItem);

  return [...exactItems, ...similarItems];
};

/**
 * Evaluates a set of rules against a given context and returns the result of the first matching rule.
 *
 * @typeParam T - The type of the result returned by the rule.
 * @param rules - An array of rules to evaluate. Each rule contains conditions and an associated result.
 * @param context - An object representing the context with key-value pairs to evaluate against the rule conditions.
 * @returns The result of the first rule whose conditions are satisfied by the context, or `null` if no rules match.
 */
export const getContentItemsFromScrollInfinitrCards = (
  resultCards: Array<Content>,
): Array<ContentItem> => {
  const Items = resultCards.map(contentToContentItem);

  return Items;
};

export const evaluateRules = <T>(
  rules: Array<Rule<T>>,
  context: Record<string, any>,
): T | null =>
  rules.find((rule) =>
    Object.entries(rule.conditions).every(([key, value]) => {
      const contextValue = context[key];

      if (Array.isArray(value)) {
        return value.includes(contextValue);
      }

      return contextValue === value;
    }),
  )?.result ?? null;

/**
 * Formats a given timestamp (in seconds) into a relative "time ago" string,
 * showing how long ago it occurred based on the current time.
 *
 * If less than an hour has passed, it returns just the number of minutes.
 * If less than a day has passed, it returns the elapsed hours and minutes in the format "Xh Y".
 * If more than a day has passed, it still returns hours and minutes from the most recent day boundary.
 *
 * @param timeInSeconds - The timestamp in seconds (e.g., a Unix epoch timestamp) representing the past time.
 * @returns A formatted string representing how long ago that time occurred.
 *
 * @example
 * // If the given time was 30 minutes ago, it might return "30".
 * // If the given time was 5 hours and 20 minutes ago, it might return "5h 20".
 */
export const formatTimeAgo = (timeInSeconds: number) => {
  const timeInMilliseconds = timeInSeconds * 1000;
  const now = new Date().getTime();
  const diffInMinutes = Math.floor((now - timeInMilliseconds) / (1000 * 60));

  if (diffInMinutes < 60) {
    return `${diffInMinutes}`;
  }

  if (diffInMinutes < 24 * 60) {
    // less than a day
    const hours = Math.floor(diffInMinutes / 60);
    const minutes = diffInMinutes % 60;

    return `${hours}h ${minutes}`;
  }

  const hours = Math.floor((diffInMinutes % (24 * 60)) / 60);
  const minutes = diffInMinutes % 60;

  return `${hours}h ${minutes}`;
};

/**
 * Formats a date to display relevant information based on the context.
 * This function determines if an event has already ended, occurs within the same week,
 * or is in the past or future, and returns a representative string in the appropriate format.
 *
 * @param {number} start_time - The start time of the event in UNIX format (seconds).
 * @param {number} end_time - The end time of the event in UNIX format (seconds).
 * @returns {string} A formatted string representing:
 *  - "FINISHED" if the event has already ended.
 *  - "Today | HH:mm" if the event is today.
 *  - "Day of the week | HH:mm" if the event is within the next 7 days.
 *  - "Day Month | HH:mm" for dates outside the current week.
 *
 * Example:
 * - If the event has ended: "FINISHED".
 * - If it's today at 2:30 PM: "Today | 14:30".
 * - If it's on Tuesday at 9:00 AM: "Tuesday | 09:00".
 * - If it's a past or future date outside this week: "12/Dec | 16:45".
 */
export const FormatDateScheduler = (
  start_time: number,
  end_time: number,
): string => {
  const MILLISECONDS_IN_A_DAY = 1000 * 60 * 60 * 24;
  const MILLISECONDS_IN_A_SECOND = 1000;

  const { i18n } = useI18n();

  const currentEpoch = nowTime();
  const isFinished = isEnded(end_time, currentEpoch);
  const isLive = isCurrentLive(start_time, end_time, currentEpoch);

  if (isFinished) {
    return i18n.gettext('FINALIZADO');
  }

  if (isLive) {
    return i18n.gettext('EN VIVO');
  }

  const date = new Date(start_time * 1000);
  const today = new Date();
  const daysOfWeek = [
    i18n.gettext('DOMINGO'),
    i18n.gettext('LUNES'),
    i18n.gettext('MARTES'),
    i18n.gettext('MIÉRCOLES'),
    i18n.gettext('JUEVES'),
    i18n.gettext('VIERNES'),
    i18n.gettext('SÁBADO'),
  ];
  const months = [
    i18n.gettext('ENE'),
    i18n.gettext('FEB'),
    i18n.gettext('MAR'),
    i18n.gettext('ABR'),
    i18n.gettext('MAY'),
    i18n.gettext('JUN'),
    i18n.gettext('JUL'),
    i18n.gettext('AGO'),
    i18n.gettext('SEP'),
    i18n.gettext('OCT'),
    i18n.gettext('NOV'),
    i18n.gettext('DIC'),
  ];

  // Get the hours and minutes with 24-hour format
  const hours = date.getHours().toString().padStart(2, '0');
  const minutes = date.getMinutes().toString().padStart(2, '0');

  // Calculate the difference in days
  const startOfDayDate = new Date(
    date.getFullYear(),
    date.getMonth(),
    date.getDate(),
    0,
    0,
    0,
  );

  const startOfDayToday = new Date(
    today.getFullYear(),
    today.getMonth(),
    today.getDate(),
    0,
    0,
    0,
  );

  // Difference in days
  const diffDays = Math.round(
    (startOfDayDate.getTime() - startOfDayToday.getTime()) /
      MILLISECONDS_IN_A_DAY,
  );

  if (diffDays === 0) {
    // If it's today, show "Today | time"
    return `${i18n.gettext('HOY')} | ${hours}:${minutes}`;
  }

  if (diffDays === 1) {
    // If it's today, show "Today | time"
    return `${i18n.gettext('MAÑANA')} | ${hours}:${minutes}`;
  }

  const todayInSeconds = today.getTime() / MILLISECONDS_IN_A_SECOND;

  if (diffDays >= -6 && diffDays <= 6 && start_time > todayInSeconds) {
    // If it's within the week, show "Day of the week | time"
    return `${daysOfWeek[date.getDay()]} | ${hours}:${minutes}`;
  }

  const day = date.getDate();
  const month = months[date.getMonth()];
  const result = `${day}/${month} | ${hours}:${minutes}`;

  return result;
};

/**
 * Enum for the live status states when the channel is live
 */
export enum LiveTimeStatus {
  CURRENT = 'current',
  FUTURE = 'future',
}

/**
 * Determines if a live channel is currently active or scheduled for the future
 *
 * @param start_time - Timestamp in seconds (epoch) representing the event start time
 * @param end_time - Timestamp in seconds (epoch) representing the event end time
 * @returns LiveTimeStatus | null - Returns the time status if the time range is valid, null otherwise
 */
export const getLiveTimeStatus = (
  start_time: number,
  end_time: number,
): LiveTimeStatus | null => {
  const now = new Date().getTime();
  const currentEpoch = Math.floor(now / 1000);

  if (currentEpoch >= start_time && currentEpoch <= end_time) {
    return LiveTimeStatus.CURRENT;
  }

  if (currentEpoch < start_time) {
    return LiveTimeStatus.FUTURE;
  }

  return null;
};

/**
 * Formats a given timestamp into a string representing the time in HH:MM format.
 *
 * @param {number} timestamp - The timestamp to format, in seconds.
 * @returns {string} The formatted time string in HH:MM format.
 */
export const formatTimeHM = (timestamp: number) => {
  const date = new Date(timestamp * 1000);

  const hours = date.getHours().toString().padStart(2, '0');
  const minutes = date.getMinutes().toString().padStart(2, '0');

  return `${hours}:${minutes}`;
};

/**
 * Formats a time range into a string with the format "HH:mm - HH:mm".
 *
 * @param startTime - The starting timestamp in seconds.
 * @param endTime - The ending timestamp in seconds.
 * @returns A string representing the formatted time range.
 */
export const formatTimeRange = (startTime: number, endTime: number): string => {
  const formatTime = (timestamp: number) => {
    const date = new Date(timestamp);
    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');

    return `${hours}:${minutes}`;
  };
  const startFormatted = formatTime(startTime * 1000);
  const endFormatted = formatTime(endTime * 1000);

  return `${startFormatted} - ${endFormatted}`;
};

/**
 * Determines whether the Loyalty card should be displayed on the current device.
 * The card is shown if the user has Loyalty **or** is a MeliPlus member, and the operating system is Android.
 *
 * @param is_loyalty - Indicates if the user has Loyalty.
 * @param osName - The name of the device's operating system.
 * @returns `true` if the Loyalty card should be displayed, `false` otherwise.
 */
export const isLoyaltyCard = (is_loyalty: boolean, osName?: string): boolean =>
  is_loyalty && osName === 'android';

/**
 * Retrieves the URL of the 'cardlogo' image from an array of images or falls back to the first image URL.
 *
 * @param {Array<Image>} images - An array of image objects with `type` and `url` properties.
 * @returns {string | null} The URL of the 'cardlogo' image, the first image URL, or `null` if none exists.
 *
 * @example
 * getUrlLogoCard([{ type: 'cardlogo', url: 'https://logo.com' }]); // 'https://logo.com'
 * getUrlLogoCard([{ type: 'thumbnail', url: 'https://thumbnail.com' }]); // 'https://thumbnail.com'
 * getUrlLogoCard([]); // null
 */
export const getUrlLogoCard = (images: Array<Image>) => {
  if (!images || images.length === 0) {
    return null;
  }

  const image = images?.find((image) => image.type === 'backdrop');

  if (image?.url) {
    return image.url;
  }

  return images[0].url || null;
};

/**
 * Checks if any of the given minimum (data) restrictions
 * are present in the content's restrictions array.
 *
 * @param dataRestrictions - List of "critical" or minimum restrictions to check.
 * @param restrictions - Array of restrictions associated with the content (may be undefined).
 * @returns True if there's at least one intersection between dataRestrictions and restrictions; otherwise, false.
 */
export const hasMinimumRestrictions = (
  dataRestrictions: Array<string>,
  restrictions?: Array<string>,
): boolean => {
  if (!restrictions || restrictions.length === 0) {
    return false; // no restrictions
  }

  return dataRestrictions.some((r) => restrictions.includes(r));
};
