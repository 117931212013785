export const formatTime = (seconds: number) => {
  const formatted =
    seconds < 3_600
      ? new Date(seconds * 1000).toISOString().substring(14, 19)
      : new Date(seconds * 1000).toISOString().substring(11, 19);

  return formatted.replace(/^0/, '');
};

/**
 * Converts a time string in the "HH:mm hs." format to a Unix timestamp (in seconds).
 *
 * @param {string} timeString - A time string in the format "HH:mm hs.",
 *     for example "16:10 hs.". The substring " hs." will be removed before parsing the time.
 * @returns {number} The integer representing the specified time (based on the current day)
 *     in seconds since the Unix epoch (January 1, 1970, 00:00 UTC).
 *
 * @example
 * // Example usage:
 * const input = "16:10 hs." or "16:10";
 * const unixTimestamp = convertTimeToUnixTimestamp(input);
 * console.log(unixTimestamp); // e.g. 1696356600 (depending on today's date)
 */
export const convertTimeToUnixTimestamp = (timeString: string): number => {
  // Remove the " hs." substring from the time string if exist
  const cleanedTime = timeString.replace(' hs.', '').trim();

  // Get the current date
  const now = new Date();

  // Split the cleaned time string into hours and minutes
  const [hours, minutes] = cleanedTime.split(':').map(Number);

  // Create a new Date object with the specified time (using the current year, month, and day)
  const fullDateTime = new Date(
    now.getFullYear(),
    now.getMonth(),
    now.getDate(),
    hours,
    minutes,
  );

  // Convert the Date object to a Unix timestamp (in seconds)
  return Math.floor(fullDateTime.getTime() / 1000);
};
